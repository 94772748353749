<template>
    <div :class="expectColor">
        {{row['usedTime']}}
    </div>
</template>
<script>
    import {getExpectColor} from "@/js/helper.js";

    export default {
        props: ["row"],
        computed: {
            expectColor() {
                return getExpectColor(this.row);
            },
        }
    };
</script>